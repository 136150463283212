@import url(./variables.css);

@media (max-width: 1025px) {
  .mobile-navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    font-family: var(--semibold-text);
    font-size: var(--menu-txt-size);
    color: var(--color-black);
  }

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    box-shadow: var(--box-shadow);
    background-color: var(--color-white);
  }

  .menu-icon {
    cursor: pointer;
  }

  .menu-items {
    background-color: var(--color-white);
    position: fixed;
    right: -100%;
    width: 50% !important;
    height: 800px;
    transition: ease-out 0.5s !important;
    box-shadow: var(--box-shadow);
  }

  .menu-items.open {
    display: block;
    right: 0;
  }

  .menu-items ul {
    list-style: none;
    padding: 0;
  }

  .menu-items ul li {
    align-content: center;
  }

  .menu-items ul li a {
    font-size: var(--menu-txt-size);
    color: var(--color-black);
    text-decoration: none;
    
  }

  .full-width-navlink {
    display: block; 
    width: 100%; 
    padding: 8px 0 8px 7%; 
}


.full-width-navlink-sub{
  display: block; 
  width: 100%; 
  padding: 0 0 0 7%; 
  
}

  .menu-items ul li a:hover {
    color: var(--main-bg-color);
    text-decoration: underline;
  }

  .menu-items ul li a.active {
    color: var(--main-bg-color);
    text-decoration: underline;
    background-color: var(--color-light);
  }

  .menu-items ul li ul {
    display: none;
  }

  .menu-items ul li:hover ul,
  .menu-items ul li:focus-within ul {
    display: block;
  }

  .menu-items ul li ul li {
    padding: 10px 20px;
  }

  .icono-menu {
    width: 40px;
  }

  .icono-login {
    width: 45px;
    margin-right: 30px;
  }

  .logo_navemar {
    width: 250px;
    padding: 25px 0;
  }

  .menu-link {
    position: relative;
  }

  .menu-link-1 {
    display: none;
  }

  .menu-link-1.open {
    display: block;
  }

  .submenu-link {
    display: none;
  }

  .submenu-link.open {
    display: block;
  }

  .submenu-link-2 {
    display: none;
  }

  .submenu-link-2.open {
    display: block;
  }

  .custom-arrow-menu {
    position: initial;
    align-content: center;
    margin-left: 15px;
    width: 9px;
  }

}

@media screen and (max-width: 768px) {
  .navbar-content {
    padding: 0 50px;
  }

  .icono-menu {
    width: 24px;
  }

  .icono-login {
    width: 27px;
    margin-right: 20px;
  }

  .logo_navemar {
    width: 145px;
  }
}

@media (max-width: 600px) {
  .navbar-content {
    padding: 0 30px;
  }

  .icono-menu {
    width: 24px;
  }

  .icono-login {
    width: 27px;
    margin-right: 20px;
  }

  .logo_navemar {
    width: 145px;
    padding: 20px 0;
  }

  .menu-items {
    width: 75% !important;

  }
}