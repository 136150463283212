@import url(./variables.css);

.navbar-nav .nav-link:hover,
.navbar-nav .nav-item.active .nav-link {
  color: var(--main-bg-color);
  text-decoration: underline;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--main-bg-color) !important;
  text-decoration: underline !important;
}

.navbar {
  font-family: var(--semibold-text);
}

.navbar .container-fluid a {
  font-size: var(--menu-txt-size);
  color: var(--color-black);
}

.navbar .container-fluid-interno a {
  font-size: var(--menu-txt-size);
  color: var(--color-black);
}

.container-fluid .navbar-nav .nav-item {
  display: flex;
  align-items: center;
  height: 68px;
}

.container-fluid-interno .navbar-nav .nav-item {
  display: flex;
  align-items: center;
  height: 68px;
}

.navbar-nav {
  display: flex;
  gap: 20px;
}

.container-fluid .dropdown-menu {
  background: var(--color-white);
  width: auto;
  position: absolute;
  top: 69px;
  list-style: none;
  text-align: start;
  box-shadow: var(--box-shadow);
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-fluid-interno .dropdown-menu {
  background: var(--color-white);
  width: auto;
  position: absolute;
  top: 69px;
  list-style: none;
  text-align: start;
  box-shadow: var(--box-shadow);
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.container-fluid .dropdown-menu.clicked {
  display: none;
}

.container-fluid-interno .dropdown-menu.clicked {
  display: none;
}

.container-fluid .dropdown-menu li {
  font-family: var(--regular-text);
  font-size: var(--menu-txt-size) ;
}

.container-fluid .dropdown-menu .dropdown-item:hover {
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--color-black);
  height: 32px;
}

.dropdown-menu .dropdown-item.active {
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--main-bg-color);
}

.logo_navemar {
  width: 250px;
}

.dropdown-item {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-item:hover {
  color: #007bff;
}

.dropdown-item:focus {
  outline: none;
}

.menu-fijo {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: var(--color-white);
  width: 100% !important;
  z-index: 3;
  box-shadow: var(--box-shadow-mayor);
}

.dropdown-submenu {
  position: relative;
}

.dropdown-menu .dropdown-submenu .dropdown-menu .dropdown-item:hover {
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--color-black);
  height: 32px;
}

.dropdown-submenu .dropdown-menu .dropdown-item:target {
  color: var(--main-bg-color);
}

.dropdown-menu .dropdown-submenu .dropdown-menu .dropdown-item.active {
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--main-bg-color);
}

.dropdown-submenu:hover .dropdown-menu,
.dropdown-submenu:focus .dropdown-menu {
  display: block;
  position: absolute;
  left: 100%;
  top: 0 !important;
}

.dropdown-menu .dropdown-submenu .dropdown-menu {
  width: 220px;
  position: absolute;
  top: -8px;
  list-style: none;
  text-align: start;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-submenu-2 {
  position: relative;
}

.dropdown-submenu .dropdown-submenu-2 .dropdown-menu .dropdown-item:hover {
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--color-black);
  height: 32px;
}

.dropdown-submenu .dropdown-submenu-2 .dropdown-menu .dropdown-item:target {
  color: var(--main-bg-color);
}

.dropdown-submenu .dropdown-submenu-2 .dropdown-menu .dropdown-item.active {
  box-shadow: var(--box-shadow);
  background-color: var(--color-light);
  color: var(--main-bg-color);
}

.dropdown-submenu .dropdown-submenu-2:hover .dropdown-menu,
.dropdown-submenu .dropdown-submenu-2:focus .dropdown-menu {
  display: block;
}

.dropdown-submenu .dropdown-submenu-2 .dropdown-menu {
  display: none;
}

.div-menu.active{
  border: 0;
}

.icono-menu{
  color:var(--main-bg-color);
  width: 40px;
}

.custom-arrow-menu{
  width: 7px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-arrow-submenu{
  width: 7px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-item.dropdown-toggle::after{
  display: none;
 }

.container-fluid .navbar-nav .nav-link.dropdown-toggle{
  position: relative;
  padding-right: 20px !important;
}

.nav-link.dropdown-toggle::after{
 display: none;
}

.container-fluid .dropdown-submenu .dropdown-item.dropdown-toggle{
  position: relative;
  padding-right: 80px;
}

.link-perfil-menu{
  color: var(--color-white) !important;
  text-decoration: none !important;
}

.link-perfil-menu:hover{ 
  color: var(--main-bg-color)  !important;
}

.link-panel-menu{
  color: var(--main-bg-color) !important;
  text-decoration: none !important;
}

.link-panel-menu:hover{ 
  color: var(--color-white)  !important;
}

.icon-custom {
  width: 30px;
}

@media screen and (max-width: 1024px) {

  .logo_navemar {
    width: 250px;
  } 
}

@media screen and (max-width: 600px) {

  .logo_navemar {
    width: 145px;
  }

  .icono-menu{
    width: 25px;
  }
  
}




