:root{
  color-scheme: only light;
}
html, body, #root {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
}

.container-full-width {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-top: 68px;
}

@media screen and (max-width: 1024px) {
  .container-full-width {
    padding-top: 110px;
  }
}

@media screen and (max-width: 768px) {
  .container-full-width {
    padding-top: 85px;
  }
}

@media screen and (max-width: 600px) {
  .container-full-width {
    padding-top: 75px;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
    color: black;
  }
}
