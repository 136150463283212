@font-face {
    font-family: 'Poppins-Bold';
    src: url('./Poppins-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./Poppins-SemiBold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./Poppins-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('./Poppins-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}