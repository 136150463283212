.cookie-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: var(--semibold-text) !important;
  font-size: 13px;
}

.cookie-modal-content {
  background: var(--color-white);
  padding: 35px 20px;
  border-radius: 25px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 570px;
}

.cookie-modal-tittle {
  margin-left: 5px;
  padding: 15px 22px;
  width: 100%;
  border-top: 1px solid var(--color-borde-tramites);
  border-bottom: 1px solid var(--color-borde-tramites);
  align-items: center;
}

.cookie-modal-body {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--color-borde-tramites);
  flex-grow: 1;
  overflow: hidden;
}

.cookie-modal-tabs {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-right: 20px;
}

.cookie-modal-tabs button {
  padding: 12px;
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-borde-tramites);
  border-left: 14px solid transparent;
  cursor: pointer;
  text-align: left;
  width: 100%;
  color: var(--main-bg-color);
}

.cookie-modal-tabs button.active {
  border-left: 14px solid var(--main-bg-color);
}

.cookie-modal-text {
  width: 70%;
  padding-top: 15px;
  padding-right: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.cookie-modal-footer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.cookie-modal-footer button {
  background-color: var(--main-bg-color);
  color: var(--color-white);
  padding: 8px 25px;
  border-radius: 8px;
  font-size: var(--button-txt-size);
  font-family: var(--medium-text);
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-button-color);
  min-width: var(--min-width-button);
  margin: 0 10px;
}

.cookie-modal-footer button:hover {
  background-color: var(--main-button-hover);
  color: var(--main-button-color);
  border: 2px solid var(--main-button-color);
}

.cookie-modal-footer .btn-third {
  background-color: var(--third-button-color);
  color: var(--main-button-color);
  border: 2px solid var(--third-button-hover);
}

.cookie-modal-footer .btn-third:hover {
  background-color: var(--third-button-hover);
  color: var(--color-white);
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.semibold-subtitulo {
  font-family: var(--semibold-text) !important;
}

.semibold-subtitulo-azul {
  color: var(--main-bg-color);
  font-family: var(--semibold-text) !important;
}

.switch-cookies {
  width: max-content;
  display: block;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.switch-cookies .form-check {
  width: 100%;
  text-align: center;
}

.switch-cookies .form-check-label {
  display: block;
  margin-bottom: 5px;
  font-family: var(--semibold-text);
}

.switch-cookies .form-check-input:checked {
  background-color: var(--main-bg-color) !important;
  border-color: var(--main-bg-color) !important;
}

.switch-cookies .form-check-input:checked+.form-check-label::after {
  background-color: white !important;
}

.switch-cookies .form-check-input {
  background-color: #f4f4f4 !important;
  border-color: var(--secondary-button-color) !important;
}

.switch-cookies .form-check-input {
  width: 2.5em !important;
  height: 1.3em !important;
  border-radius: 1.25em !important;
}

@media screen and (max-width: 1024px) {
  .cookie-modal-content {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .cookie-modal {
    font-size: var(--mobile-txt-size);
  }

  .cookie-modal-content {
    padding: 35px 10px;
    width: 90%;
  }

  .cookie-modal-footer button {
    font-size: var(--mobile-txt-size);
  }
}