@import url(./variables.css);
.vertical-button {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    writing-mode: vertical-rl;
    z-index: 2000; 
}

.vertical-button-content {
    background-color: var(--main-bg-color);
    font-family: var(--regular-text) !important;
    color: var(--color-white);
    padding: 15px 8px;
    font-size: var(--main-txt-size);
    cursor: pointer;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    outline: none;
    border: 1px solid var(--main-bg-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: var(--box-shadow);
}

.vertical-button-content:hover {
    background-color: var(--main-button-hover);
    color: var(--main-button-color);    
    border: 1px solid var(--main-button-color);
}
@media screen and (max-width: 767px) {
    .vertical-button-content {
        padding: 10px 1px;
        font-size: var(--mobile-txt-size);
    }
} 