@import url(./variables.css);

.modal-header .btn-close {
  color: red;
}


.rounded-modal .modal-content {
  border: 1px solid var(--color-white) !important;
  padding: 5px;
  border-radius: 25px;
  
}

.rounded-modal .modal-dialog {
  max-width: 100%;
  width: auto;
  padding: 0 30%;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.modal-footer button {
  background-color: var(--main-bg-color);
  color: var(--color-white);
  padding: 8px 25px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-bg-color);
  min-width: var(--min-width-button-modal);
  font-size: var(--button-txt-size-modal);
  font-family: var(--medium-text);

}

.modal-footer button:hover {
  background-color: var(--main-button-hover);
  color: var(--main-button-color);
  border: 2px solid var(--main-button-color);
}

.modal-footer .btn-secondary {
  background-color: var(--secondary-button-color);
  color: var(--color-black);
  border: 2px solid var(--secondary-button-color);

}

.modal-footer .btn-secondary:hover {
  background-color: var(--secondary-button-hover);
  color: var(--color-black);
}

.modal-footer .btn-third {
  background-color: var(--third-button-color) !important;
  color: var(--main-button-color) !important;
  border: 2px solid var(--third-button-hover) !important;

}

.modal-footer .btn-third:hover {
  background-color: var(--third-button-hover) !important;
  color: var(--color-white) !important;
}

.modal_imagen {
  height: 60px;
  margin-bottom: 15px;
}

.modal-title-centered {
  text-align: center;
  margin: 0 10%;
}

.mensaje-modal{
  margin: 0 10%;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}
@media screen and (min-width: 2000px) {
  .rounded-modal .modal-dialog {
    padding: 0 37%;
  }
}

@media screen and (max-width: 1000px) {
  .modal_imagen {
    height: 40px;
  }
}

@media screen and (max-width: 750px) {
  .rounded-modal .modal-dialog {
    padding: 0 20%;
  }

  .modal-footer button {
    min-width: var(--mobile-min-width-button-modal);
    font-size: var(--mobile-button-txt-size-modal);
  }

  .mensaje-modal{
    margin: 0;
  }

  .modal-title-centered {
    text-align: center;
    margin: 0;
  }

}

@media screen and (max-width: 530px) {
  .rounded-modal .modal-dialog {
    padding: 0 5%;
  }

  .modal-footer button {
    min-width: var(--mobile-min-width-button-modal);
    font-size: var(--mobile-button-txt-size-modal);
  }

  .modal_imagen {
    height: 40px;
  }
}