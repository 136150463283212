@import url(./components/styles/variables.css);

body {
  margin: 0;
  font-family: var(--regular-text) !important;
  font-size: var(--main-txt-size) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}

.custom-container {
  margin-left: 5% !important;
  margin-right: 5% !important;
  width: calc(100% - 10%) !important;
}

.container-fluid button {
  background-color: var(--main-bg-color);
  color: var(--color-white);
  padding: 8px 25px;
  border-radius: 8px;
  font-size: var(--button-txt-size);
  font-family: var(--medium-text);
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-button-color);
  min-width: var(--min-width-button);
}

.container-fluid button:hover {
  background-color: var(--main-button-hover);
  color: var(--main-button-color);
  border: 2px solid var(--main-button-color);
}

.container-fluid button:disabled {
  cursor: not-allowed;
  background-color: var(--main-bg-claro);
  color: var(--color-disabled);
  border: 2px solid var(--main-bg-claro);
  box-shadow: none;
}

.texto-azul {
  color: var(--main-bg-color);
}

.texto-azul-link {
  color: var(--main-bg-color);
  text-decoration: none;
}

.container-fluid input[type=text],
input[type=email],
input[type=tel],
input[type=password],
select {
  background: #F8F9FD;
  padding: 15px 25px;
  border: 0;
}

.container-fluid textarea {
  background: #F8F9FD;
  padding: 15px;
  border: 0;
}

.container-fluid .btn-secondary {
  background-color: var(--secondary-button-color);
  color: var(--color-black);
  border: 2px solid var(--secondary-button-color);
}

.container-fluid .btn-secondary:hover {
  background-color: var(--secondary-button-hover);
  color: var(--color-black);
}

.container-fluid .btn-third {
  background-color: var(--third-button-color);
  color: var(--main-button-color);
  border: 2px solid var(--third-button-hover);

}

.container-fluid .btn-third:hover {
  background-color: var(--third-button-hover);
  color: var(--color-white);
}

.container-fluid .btn-light-clientes {
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: var(--box-shadow-clientes);
  border: 2px solid var(--color-white);
  border-radius: 12px;
  width: fit-content;
  min-width: 50px;
  padding: 8px 35px;
}

.container-fluid .btn-light-clientes:hover {
  background-color: var(--main-bg-claro);
  border: 2px solid var(--main-bg-claro);
}

.container-fluid-interno {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}

.container-fluid-interno .btn-primary {
  background-color: var(--main-bg-color);
  color: var(--color-white);
  padding: 8px 25px;
  border-radius: 8px;
  font-size: var(--button-txt-size);
  font-family: var(--medium-text);
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-button-color);
  min-width: var(--min-width-button);
}

.container-fluid-interno .btn-primary:hover {
  background-color: var(--main-button-hover);
  color: var(--main-button-color);
  border: 2px solid var(--main-button-color);
}

.container-fluid-interno button:disabled {
  cursor: not-allowed;
  background-color: var(--main-bg-claro);
  color: var(--color-disabled);
  border: 2px solid var(--main-bg-claro);
  box-shadow: none;
}

.container-fluid-interno input[type=text],
input[type=email],
input[type=tel],
input[type=date],
input[type=time],
input[type=password],
select {
  background: #F8F9FD;
  padding: 15px 25px;
  border: 0;
}

.container-fluid-interno textarea {
  background: #F8F9FD;
  padding: 15px;
  border: 0;
}

.container-fluid-interno .btn-secondary {
  background-color: var(--secondary-button-color);
  color: var(--color-black);
  border: 2px solid var(--secondary-button-color) !important;
  padding: 8px 25px;
  border-radius: 8px;
  font-size: var(--button-txt-size);
  font-family: var(--medium-text);
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-button-color);
  min-width: var(--min-width-button);
}

.container-fluid-interno .btn-secondary:hover {
  background-color: var(--secondary-button-hover);
  color: var(--color-black);
  border: 2px solid var(--main-button-color) !important;
}

.container-fluid-interno .btn-third {
  background-color: var(--third-button-color);
  color: var(--main-button-color);
  border: 2px solid var(--third-button-hover);
  padding: 8px 25px;
  border-radius: 8px;
  font-size: var(--button-txt-size);
  font-family: var(--medium-text);
  box-shadow: var(--box-shadow);
  border: 2px solid var(--main-button-color);
  min-width: var(--min-width-button);
}

.container-fluid-interno .btn-third:hover {
  background-color: var(--third-button-hover);
  color: var(--color-white);
  border: 2px solid var(--main-button-color);
}

.container-fluid-interno .btn-light-clientes {
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: var(--box-shadow-clientes);
  border: 2px solid var(--color-white);
  border-radius: 12px;
  width: fit-content;
  min-width: 50px;
  padding: 8px 35px;
}

.container-fluid-interno .btn-light-clientes:hover {
  background-color: var(--main-bg-claro);
  border: 2px solid var(--main-bg-claro);
  border: 2px solid var(--main-button-color);
}

h1 {
  margin-top: 0;
  font-family: var(--bold-text);
  font-size: var(--h1-size);
  letter-spacing: -1px;
}

h2 {
  font-family: var(--bold-text) !important;
  font-size: var(--h2-size) !important;
}

h3 {
  font-family: var(--bold-text) !important;
  font-size: var(--h3-size) !important;
}

h4 {
  font-family: var(--bold-text) !important;
  font-size: var(--h4-size) !important;
}

h5 {
  font-family: var(--bold-text) !important;
  font-size: var(--h5-size) !important;
}

p {
  font-family: var(--regular-text) !important;
  font-size: var(--main-txt-size);
}

.subtitulo {
  font-family: var(--semibold-text) !important;
  font-size: var(--main-subtitle-size);
}

.subtitulo-regular {
  font-family: var(--regular-text) !important;
  font-size: var(--main-subtitle-size);
}

.topic-blog {
  font-family: var(--semibold-text) !important;
  font-size: var(--blog-topic-size);
}

.titulo-blog {
  font-family: var(--semibold-text) !important;
  font-size: var(--blog-titulo-size);
}

.imghome img {
  border-radius: 25px;
  width: 90%;
}

.bgclaro {
  background-color: var(--main-bg-claro);
}

.iconos {
  height: 25px;
}

.iconos-aerolinea {
  height: 40px;
}

.iconos-contacto {
  height: 15px;
}

.card-services {
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 30px !important;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-mayor);
  min-height: 300px;
  border: 0 !important;
}

.card-text-aero {
  font-family: var(--regular-text);
}

.card-text-rep {
  font-family: var(--regular-text);
  font-size: 11px !important;

}

.card.card-services-blue {
  padding-top: 5%;
  padding-bottom: 5%;
  border-radius: 30px !important;
  background-color: var(--color-light);
  box-shadow: var(--box-shadow-mayor);
  min-height: 300px;
  border: 0 !important;
}

.card.card-services-gray {
  padding-top: 7%;
  padding-bottom: 7%;
  border-radius: 30px !important;
  background-color: #f2f4ff;
  box-shadow: var(--box-shadow-mayor);
  min-height: 280px;
  border: 0 !important;
}

.card-services:hover,
.card-services-blue:hover,
.card.card-services-gray:hover {
  transform: scale(1.1);
  z-index: 2;
}

.logo-container {
  border: 1px #ccc;
  border-radius: 10px;
  padding: 2%;
  box-shadow: var(--box-shadow-mayor);
}

.logo-container:hover {
  transform: scale(1.1);
  z-index: 2;
}

.logo-container-tecnologia {
  border: 1px #ccc;
  border-radius: 10px;
  padding: 25px;
  box-shadow: var(--box-shadow-mayor);
  width: 200px;
  height: max-content;
}

.logo-container-tecnologia:hover {
  transform: scale(1.1);
  z-index: 2;
}

.logo-container-tecnologia img {
  width: 100%;
  height: auto;
}

.logo-img {
  width: 120px;
  height: auto;
}

.certifications-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 300px;
}

.ancho-indicadores {
  width: 20% !important;
}

.certifications-container-border {
  border-top: 1px solid var(--main-bg-color);
  border-bottom: 1px solid var(--main-bg-color);
}

.certifications-text {
  text-align: left;
}

.certifications-logos {
  display: flex;
  justify-content: space-between;
}

.logo-certification {
  width: 150px;
  height: auto;
  margin: 0 50px;
}

.logo-certification-iso {
  width: 210px;
  height: auto;
  margin: 0 50px;
}

.container-fluid .container-partner {
  width: 250px;
  border: 1px #ccc;
  border-radius: 10px;
  box-shadow: var(--box-shadow-mayor);
  display: inline-block;
  margin: 0 10px;
}

.container-fluid-interno .container-partner {
  width: 250px;
  border: 1px #ccc;
  border-radius: 10px;
  box-shadow: var(--box-shadow-mayor);
  display: inline-block;
  margin: 0 10px;
}

.container-fluid .container-partner:hover {
  transform: scale(1.05);
  z-index: 2;
}

.container-fluid-interno .container-partner:hover {
  transform: scale(1.05);
  z-index: 2;
}

.container-partner+.container-partner {
  margin-left: 40px;
}

.img-partners {
  width: 100%;
  height: auto;
}

.images-container {
  display: grid;
  gap: 35px 0;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
}

.container-fluid .container-enlaces {
  width: 200px;
  height: 120px;
  border: 1px #ccc;
  border-radius: 10px;
  box-shadow: var(--box-shadow-mayor);
  margin: auto;
  overflow: hidden;
  padding: 10px;
}

.container-fluid-interno .container-enlaces {
  width: 200px;
  height: 120px;
  border: 1px #ccc;
  border-radius: 10px;
  box-shadow: var(--box-shadow-mayor);
  margin: auto;
  overflow: hidden;
  padding: 10px;
}

.container-fluid .container-enlaces:hover {
  transform: scale(1.1);
  z-index: 2;
}

.container-fluid-interno .container-enlaces:hover {
  transform: scale(1.1);
  z-index: 2;
}

.img-enlaces {
  width: 100%;
  height: auto;
  max-height: 110px;
  margin: auto;
}

.height-space {
  height: 150px;
}

.height-space-med {
  height: 100px;
}

.height-space-min {
  height: 70px;
}

.border-bottom-blue {
  border-bottom: 1px solid var(--main-bg-color);
}

.height-space-mob {
  height: 100px;
}

.custom-list-services {
  list-style-type: none;
  padding-left: 0;
}

.custom-list-services li {
  background-image: url('./assets/icons/Icono-Suite-Sian-NAVESOFT-navemar.svg');
  background-repeat: no-repeat;
  background-position: left start;
  padding-left: 40px;
  margin-bottom: 10px;
  padding-top: 4px;
  background-size: 28px;
}

.img-indicadores {
  width: 90%;
}

.img-indicadores-puertos {
  width: 95%;
}

.botones-nuestros-servicios {
  flex-direction: row;
  justify-content: center;
}

.texto-bold {
  color: black;
  font-weight: bold;
  font-family: var(--bold-text) !important;
}

.link-news {
  text-decoration: none;
}

.link-telefono {
  color: var(--color-black);
  text-decoration: none;
}

@media screen and (max-width: 1024px) {

  .imghome {
    margin-top: 2% !important;
  }

  .height-space {
    height: 60px;
  }

  .container-partner+.container-partner {
    margin-left: 20px;
  }

  .images-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .container-fluid .container-enlaces {
    height: auto;
  }

  .container-fluid-interno .container-enlaces {
    height: auto;
  }

  .height-space-mob {
    height: 50px;
  }

}

@media screen and (max-width: 850px) {
  .card.card-services-gray {
    padding-top: 7%;
    padding-bottom: 7%;
    border-radius: 30px !important;
    background-color: #f2f4ff;
    box-shadow: var(--box-shadow-mayor);
    min-height: 290px;
    border: 0 !important;
  }

  .ancho-indicadores {
    width: 30% !important;
  }

}

@media screen and (max-width: 767px) {

  .certifications-container {
    margin-top: 30px;
    margin-bottom: 80px;
  }

  .container-fluid .container-enlaces {
    width: 125px;
  }

  .container-fluid-interno .container-enlaces {
    width: 125px;
  }

  .container-fluid button {
    font-size: var(--mobile-button-txt-size);
  }

  .container-fluid-interno button {
    font-size: var(--mobile-button-txt-size);
  }

  .container-fluid .btn-light-clientes {
    width: max-content;
    padding: 5px 15px;
    min-width: 50px;
  }

  .container-fluid-interno .btn-light-clientes {
    width: max-content;
    padding: 5px 15px;
    min-width: 50px;
  }

  .alinea-otros-enlaces {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .height-space {
    height: 60px;
  }

  .height-space-med {
    height: 50px;
  }

  .height-space-min {
    height: 30px;
  }

  h1 {
    font-size: var(--h1-size-mobile) !important;
  }

  h2 {
    font-size: var(--h2-size-mobile) !important;
  }

  h3 {
    font-size: var(--h3-size-mobile) !important;
  }

  h4 {
    font-size: var(--h4-size-mobile) !important;
  }

  h5 {
    font-size: var(--h5-size-mobile) !important;
  }

  p {
    font-size: var(--mobile-txt-size);
  }

  .subtitulo {
    font-size: var(--mobile-subtitle-size);
  }

  .subtitulo-regular {
    font-size: var(--mobile-subtitle-size);
  }

  .logo-certification {
    width: 107px;
    margin: 0 20px;
  }

  .logo-certification-iso {
    width: 150px;
    margin: 0 20px;
  }

  .images-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .container-fluid .container-enlaces {
    height: 80px;
  }

  .container-fluid-interno .container-enlaces {
    height: 80px;
  }

  .img-enlaces {
    max-height: 70px;
  }

  .height-space-mob {
    height: 25px;
  }

  .logo-container-tecnologia {
    width: 95%;
    padding: 10px;
  }

  .card.card-services-gray {
    padding-top: 3%;
    padding-bottom: 3%;
    min-height: 240px;
    border: 0 !important;
  }

  .botones-nuestros-servicios {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ancho-indicadores {
    width: 90% !important;
  }

  .height-space-tramites {
    height: 50px;
  }

}